import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calcProductTax',
})
export class CalcProductTaxPipe implements PipeTransform {
  transform(value: string | number, tax: number = 0): number {
    const parsedValue = +value;
    const parsedValueWithTax = parsedValue + parsedValue * (tax / 100);
    console.log('parsedValueWithTax :>> ', parsedValueWithTax);
    return parsedValueWithTax;
  }
}
